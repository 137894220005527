import { For, Show, createMemo, Switch, Match } from "solid-js";
import * as helper from "~/utils/helper_program";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import {
  getGrid,
  getRegulations,
  isPrixEncadresOverride,
} from "~/utils/helper_program";
import GridTypologyRow from "~/components/Program/Components/GridTypologyRow";
import Accordion from "@corvu/accordion";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./Grid.css";

type GridProps = {
  wrapper: ProgramWrapper;
};

export default function Grid(props: GridProps) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <Show when={!helper.isPreview(props.wrapper)}>
        <section class="grids">
          <Switch>
            <Match when={props.wrapper.program.field_disp_grid_manual}>
              <Show
                fallback={<h2>Exemples de logements</h2>}
                when={
                  props.wrapper.program.field_program_type
                    ?.drupal_internal__tid === settings.program_types.terrains
                }
              >
                <h2>Exemples de terrains</h2>
              </Show>
            </Match>
            <Match when={!props.wrapper.program.field_disp_grid_manual}>
              <Show
                fallback={<h2>Logements disponibles</h2>}
                when={
                  props.wrapper.program.field_program_type
                    ?.drupal_internal__tid === settings.program_types.terrains
                }
              >
                <h2>Terrains disponibles</h2>
              </Show>
            </Match>
          </Switch>

          <section class="grids-container">
            <Accordion>
              <For each={getGrid(props.wrapper)}>
                {(gridTypology) => (
                  <GridTypologyRow
                    gridTypology={gridTypology}
                    wrapper={props.wrapper}
                  />
                )}
              </For>
            </Accordion>
          </section>
          <div class="legals">
            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_vat_inc}
              regulationTid={settings.regulations_tids.tva_normale}
              dataTest="legals-grid-vat-inc"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_vat_inc_reduced}
              regulationTid={settings.regulations_tids.tva_reduite}
              dataTest="legals-grid-vat-inc-reduced"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={
                !isPrixEncadresOverride(props.wrapper) &&
                props.wrapper.program.field_disp_grid_vat_inc_mastered
              }
              regulationTid={settings.regulations_tids.prix_maitrises}
              dataTest="legals-grid-vat-inc-mastered"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_vat_inc_brs}
              regulationTid={settings.regulations_tids.brs}
              dataTest="legals-grid-vat-inc-brs"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_pinel}
              regulationTid={settings.regulations_tids.pinel}
              dataTest="legals-grid-pinel"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_pinel_plus}
              regulationTid={settings.regulations_tids.pinel_plus}
              dataTest="legals-grid-pinel-plus"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_patrimonial}
              regulationTid={settings.regulations_tids.patrimonial}
              dataTest="legals-grid-patrimonial"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_lmnp_vat_inc}
              regulationTid={settings.regulations_tids.lmnp_vat_inc}
              dataTest="legals-grid-lmnp-vat-inc"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_bare_ownership}
              regulationTid={settings.regulations_tids.nue_propriete}
              dataTest="legals-grid-bare-ownership"
            />

            <GridLegals
              wrapper={props.wrapper}
              dispGrid={props.wrapper.program.field_disp_grid_lmnp_vat_ex}
              regulationTid={settings.regulations_tids.lmnp_vat_ex}
              dataTest="legals-grid-lmnp-vat-ex"
            />

            <Show
              when={
                props.wrapper.program.field_disp_grid_manual &&
                props.wrapper.program.legals_manual
              }
            >
              <p
                data-test="legals-grid-manual"
                innerHTML={props.wrapper.program.legals_manual}
              />
            </Show>
          </div>
        </section>
      </Show>
    </>
  );
}

function GridLegals(props: {
  wrapper: ProgramWrapper;
  dispGrid: boolean;
  regulationTid: number;
  dataTest: string;
}) {
  const legalsGrid = createMemo(() => {
    return getRegulations(props.wrapper).find(
      (obj) => obj.drupal_internal__tid === props.regulationTid,
    )?.field_legals_grid;
  });

  return (
    <>
      <Show when={props.dispGrid && legalsGrid()}>
        <p data-test={props.dataTest} innerHTML={legalsGrid()} />
      </Show>
    </>
  );
}
